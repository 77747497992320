module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ordination Dr. Astrid Jörg-Koutromanos","short_name":"Dr. Joerg-Koutromanos","start_url":"/","background_color":"#fff","theme_color":"#701416","display":"standalone","icon":"./src/assets/images/favicon-32x32.png","icons":[{"src":"./src/assets/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/assets/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8268f4401c000ef61d48627e2fb8f585"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
