// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bereitschaftsdienste-js": () => import("./../../../src/pages/bereitschaftsdienste.js" /* webpackChunkName: "component---src-pages-bereitschaftsdienste-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-myline-js": () => import("./../../../src/pages/myline.js" /* webpackChunkName: "component---src-pages-myline-js" */),
  "component---src-pages-ordination-js": () => import("./../../../src/pages/ordination.js" /* webpackChunkName: "component---src-pages-ordination-js" */),
  "component---src-pages-ordinationszeiten-js": () => import("./../../../src/pages/ordinationszeiten.js" /* webpackChunkName: "component---src-pages-ordinationszeiten-js" */),
  "component---src-pages-rezeptbestellung-js": () => import("./../../../src/pages/rezeptbestellung.js" /* webpackChunkName: "component---src-pages-rezeptbestellung-js" */),
  "component---src-pages-ueber-js": () => import("./../../../src/pages/ueber.js" /* webpackChunkName: "component---src-pages-ueber-js" */)
}

